import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { DateField } from "@mui/x-date-pickers/DateField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";
import * as moment_precise_range_plugin from "moment-precise-range-plugin";

import React, { useState, useEffect } from "react";

import { CircularProgress, FormControl, FormHelperText } from "@mui/material";

function MainUserForm({ nextHandler, save }) {
  const [showNameError, setShowNameError] = useState(false);
  const replace = (str) => {

    const regex = / (?:day|days) .*/g;
    str = str.replace(regex, ' days');
    
    str = str.replace("hours","");
    str = str.replace("seconds","");

    return (
      str
        .slice()
        .replace("years", "anos")
        .replace("year", "anos")
        .slice()
        .replace("months", "meses")
        .replace("month", "meses")
        .slice()
        .split("days")[0] + "dias"
    );
  };

  const [loading, setLoading] = useState(false);

  const getIdadeCorrigida = (birthDate, weeksInBloom) => {
    const starts = moment(birthDate);
    const ends = moment();
    if (parseInt(weeksInBloom) <= 36) {
      starts.add(40 - weeksInBloom, "weeks");
    }

    const initialStarts = moment(birthDate);
    let preciseDiff = moment.preciseDiff(starts, ends);
    const diff = ends.diff(starts, "months", true);

    if (diff < 24) return preciseDiff.slice();

    return moment.preciseDiff(initialStarts, ends).slice();
  };

  const getIdadeCronologica = (birthDate) => {
    const starts = moment(birthDate);
    const ends = moment();

    return moment.preciseDiff(starts, ends).slice();
  };

  const bd = "2000-04-17";
  const idadeCorrigida = replace(getIdadeCorrigida(bd.slice()));
  const idadeCronologica = replace(getIdadeCorrigida(bd.slice(), 40));

  const [formData, setFormData] = useState({
    sex: "Masculino",
    name: "",
    birthDate: "2000-04-17",
    cns: "",
    idadeGestacional: 40,
    idadeCorrigida: idadeCorrigida,
    idadeCronologica: idadeCronologica,
    checkupDate: moment().format("DD-MM-YYYY"),
  });

  useEffect(() => {
    setFormData((fd) => {
      return {
        ...fd,
        idadeCronologica: replace(getIdadeCronologica(fd.birthDate)).slice(),
        idadeCorrigida: replace(
          getIdadeCorrigida(fd.birthDate, fd.idadeGestacional)
        ).slice(),
      };
    });
  }, [formData.birthDate, formData.idadeGestacional]);

  return (
    <Card fullWidth>
      <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
        textAlign="center"
        fullWidth
      >
        <MDTypography variant="h4" fontWeight="medium" color="white">
          Dados do Paciente
        </MDTypography>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{ mt: 1, mb: 2 }}
        ></Grid>
      </MDBox>
      <MDBox sx={{ margin: 2 }}>
        <MDBox component="form" role="form">
          <MDBox sx={{ width: "100%" }} fullWidth>
            <FormControl error={showNameError} fullWidth>
              <MDInput
                label="Nome"
                variant="standard"
                required={true}
                onChange={(ev) => {
                  setFormData({ ...formData, name: ev.target.value });
                  setShowNameError(false);
                }}
                value={formData.name}
                fullWidth
                error={showNameError}
              />
              {showNameError && (
                <FormHelperText>Porfavor especifique o nome</FormHelperText>
              )}
            </FormControl>
          </MDBox>
          <MDBox>
            <MDInput
              value={formData.cns}
              onChange={(ev) => {
                setFormData({ ...formData, cns: ev.target.value });
              }}
              label="CNS"
              variant="standard"
              fullWidth
            />
          </MDBox>
          <MDBox mb={2} mt={2}>
            <InputLabel>Data de nascimento</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateField"]}>
                <DateField
                  required={true}
                  value={dayjs(formData.birthDate)}
                  onChange={(newValue) => {
                    setFormData({
                      ...formData,
                      birthDate: newValue.toString(),
                    });
                  }}
                  variant="standard"
                  fullWidth
                  format="DD MM YYYY"
                />
              </DemoContainer>
            </LocalizationProvider>
          </MDBox>
          <MDBox mb={2} mt={2}>
            <MDInput
              label="Semanas de gestacao"
              type="number"
              variant="standard"
              onChange={(ev) => {
                setFormData({
                  ...formData,
                  idadeGestacional: ev.target.value,
                });
              }}
              value={formData.idadeGestacional}
              fullWidth
            />
          </MDBox>
          <MDBox mb={2} mt={2}>
            <MDInput
              label="Idade corrigida"
              variant="standard"
              value={formData.idadeCorrigida}
              fullWidth
              disabled
            />
          </MDBox>

          <MDBox mb={2} mt={2}>
            <MDInput
              label="Idade cronologica"
              variant="standard"
              value={formData.idadeCronologica}
              fullWidth
              disabled
            />
          </MDBox>

          <MDBox mb={2} mt={2}>
            <InputLabel id="user-form-select-label">Sexo</InputLabel>
            <Select
              labelId="user-form-select-label"
              id="user-form-select"
              value={formData.sex}
              required={true}
              onChange={(ev) => {
                setFormData({ ...formData, sex: ev.target.value });
              }}
              displayEmpty
              renderValue={(value) => value || "Selecione um valor"}
              fullWidth
            >
              <MenuItem value={"Masculino"}>Masculino</MenuItem>
              <MenuItem value={"Feminino"}>Feminino</MenuItem>
            </Select>
          </MDBox>
          <MDBox mb={2} mt={2}>
            <InputLabel>Data de avaliação</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateField"]}>
                <DateField
                  required={true}
                  value={dayjs(formData.checkupDate)}
                  onChange={(newValue) => {
                    setFormData({
                      ...formData,
                      checkupDate: newValue.toString(),
                    });
                  }}
                  variant="standard"
                  fullWidth
                  format="DD MM YYYY"
                />
              </DemoContainer>
            </LocalizationProvider>
          </MDBox>

          <MDBox mt={4} mb={1}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                nextHandler({ ...formData });
              }}
              fullWidth
            >
              Seguinte
            </MDButton>
          </MDBox>
          <MDBox mt={4} mb={1}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                if (formData.name.trim() === "") {
                  setShowNameError(true);
                } else {
                  save({ ...formData }, true, setLoading);
                }
              }}
              fullWidth
              loading={loading}
            >
              {loading ? (
                <CircularProgress color="inherit" />
              ) : (
                <b>Salvar e ir para Hine</b>
              )}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default MainUserForm;
