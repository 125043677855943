import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControlLabel from "@mui/material/FormControlLabel";
import MDButton from "components/MDButton";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import { hasUnselectedTopics } from "reducers/data_last";

import React, { useState } from "react";
import { Box } from "@mui/material";
import sec02_motores_chutar01 from "./imgs/sec02_motores_chutar01.png";
import sec02_motores_chutar02 from "./imgs/sec02_motores_chutar02.png";
import sec02_motores_chutar03 from "./imgs/sec02_motores_chutar03.png";
import sec02_motores_engatinhar01 from "./imgs/sec02_motores_engatinhar01.png";
import sec02_motores_engatinhar02 from "./imgs/sec02_motores_engatinhar02.png";
import sec02_motores_engatinhar03 from "./imgs/sec02_motores_engatinhar03.png";
import sec02_motores_engatinhar04 from "./imgs/sec02_motores_engatinhar04.png";
import sec02_motores_sentar01 from "./imgs/sec02_motores_sentar01.png";
import sec02_motores_sentar02 from "./imgs/sec02_motores_sentar02.png";
import sec02_motores_sentar03 from "./imgs/sec02_motores_sentar03.png";
import sec02_motores_sentar04 from "./imgs/sec02_motores_sentar04.png";
import motores_supino_0 from "./imgs/motores_supino_0.png";
import motores_supino_1 from "./imgs/motores_supino_1.png";
import postura_cabeca_0 from "./imgs/postura_cabeca_0.png";
import postura_cabeca_0_0 from "./imgs/postura_cabeca_0_0.png";
import postura_cabeca_0_1 from "./imgs/postura_cabeca_0_1.png";
import postura_cabeca_1 from "./imgs/postura_cabeca_1.png";
import postura_cabeca_1_0 from "./imgs/postura_cabeca_1_0.png";
import postura_cabeca_1_1 from "./imgs/postura_cabeca_1_1.png";
import postura_cabeca_3 from "./imgs/postura_cabeca_3.png";
import postura_supino_0 from "./imgs/postura_supino_0.png";
import postura_supino_1 from "./imgs/postura_supino_1.png";
import postura_supino_3 from "./imgs/postura_supino_3.png";
import postura_tronco_0 from "./imgs/postura_tronco_0.png";
import postura_tronco_1 from "./imgs/postura_tronco_1.png";
import postura_tronco_3 from "./imgs/postura_tronco_3.png";
import postura_tronco_0_1 from "./imgs/postura_tronco_0_1.png";
import postura_tronco_0_2 from "./imgs/postura_tronco_0_2.png";
import postura_tronco_0_3 from "./imgs/postura_tronco_0_3.png";
import reacao_anterior_1 from "./imgs/reacao_anterior_1.png";
import reacao_anterior_3 from "./imgs/reacao_anterior_3.png";
import reacao_braco_0 from "./imgs/reacao_braco_0.png";
import reacao_braco_1 from "./imgs/reacao_braco_1.png";
import reacao_braco_3 from "./imgs/reacao_braco_3.png";
import reacao_lateral_0 from "./imgs/reacao_lateral_0.png";
import reacao_lateral_1 from "./imgs/reacao_lateral_1.png";
import reacao_lateral_2 from "./imgs/reacao_lateral_2.png";
import reacao_lateral_3 from "./imgs/reacao_lateral_3.png";
import reacao_vertical_0 from "./imgs/reacao_vertical_0.png";
import reacao_vertical_1 from "./imgs/reacao_vertical_1.png";
import reacao_vertical_3 from "./imgs/reacao_vertical_3.png";
import tonus_cachecol_0 from "./imgs/tonus_cachecol_0.png";
import tonus_cachecol_1 from "./imgs/tonus_cachecol_1.png";
import tonus_cachecol_3 from "./imgs/tonus_cachecol_3.png";
import tonus_cachecol_0_izquerda from "./imgs/tonus_cachecol_0_izquerda.png";
import tonus_cachecol_0_direita from "./imgs/tonus_cachecol_0_direita.png";
import tonus_cachecol_3_izquerda from "./imgs/tonus_cachecol_3_izquerda.png";
import tonus_cachecol_3_direita from "./imgs/tonus_cachecol_3_direita.png";
import tonus_ombro_0 from "./imgs/tonus_ombro_0.png";
import tonus_ombro_1 from "./imgs/tonus_ombro_1.png";
import tonus_ombro_3 from "./imgs/tonus_ombro_3.png";
import tonus_popliteo_0 from "./imgs/tonus_popliteo_0.png";
import tonus_popliteo_1_0 from "./imgs/tonus_popliteo_1_0.png";
import tonus_popliteo_1_1 from "./imgs/tonus_popliteo_1_1.png";
import tonus_popliteo_2 from "./imgs/tonus_popliteo_2.png";
import tonus_popliteo_3_0 from "./imgs/tonus_popliteo_3_0.png";
import tonus_popliteo_3_1 from "./imgs/tonus_popliteo_3_1.png";
import tonus_quadril_0 from "./imgs/tonus_quadril_0.png";
import tonus_quadril_1 from "./imgs/tonus_quadril_1.png";
import tonus_quadril_2 from "./imgs/tonus_quadril_2.png";
import tonus_quadril_3 from "./imgs/tonus_quadril_3.png";
import tonus_quadril_3_0 from "./imgs/tonus_quadril_3_0.png";
import tonus_quadril_3_1 from "./imgs/tonus_quadril_3_1.png";
import tonus_sentar_0 from "./imgs/tonus_sentar_0.png";
import tonus_sentar_1 from "./imgs/tonus_sentar_1.png";
import tonus_sentar_3 from "./imgs/tonus_sentar_3.png";
import tonus_sentar_3_0 from "./imgs/tonus_sentar_3_0.png";
import tonus_sentar_3_1 from "./imgs/tonus_sentar_3_1.png";
import tonus_tornozelo_0 from "./imgs/tonus_tornozelo_0.png";
import tonus_tornozelo_1_0 from "./imgs/tonus_tornozelo_1_0.png";
import tonus_tornozelo_1_1 from "./imgs/tonus_tornozelo_1_1.png";
import tonus_tornozelo_2 from "./imgs/tonus_tornozelo_2.png";
import tonus_tornozelo_3_0 from "./imgs/tonus_tornozelo_3_0.png";
import tonus_tornozelo_3_1 from "./imgs/tonus_tornozelo_3_1.png";
import tonus_ventral_0 from "./imgs/tonus_ventral_0.png";
import tonus_ventral_1 from "./imgs/tonus_ventral_1.png";
import tonus_ventral_3_0 from "./imgs/tonus_ventral_3_0.png";
import tonus_ventral_3_1 from "./imgs/tonus_ventral_3_1.png";
import "./styles.css";

const imgs = {
  sec02_motores_engatinhar01: sec02_motores_engatinhar01,
  sec02_motores_engatinhar02: sec02_motores_engatinhar02,
  sec02_motores_engatinhar03: sec02_motores_engatinhar03,
  sec02_motores_engatinhar04: sec02_motores_engatinhar04,
  sec02_motores_chutar01: sec02_motores_chutar01,
  sec02_motores_chutar02: sec02_motores_chutar02,
  sec02_motores_chutar03: sec02_motores_chutar03,
  sec02_motores_sentar01: sec02_motores_sentar01,
  sec02_motores_sentar02: sec02_motores_sentar02,
  sec02_motores_sentar03: sec02_motores_sentar03,
  sec02_motores_sentar04: sec02_motores_sentar04,
  motores_supino_0: motores_supino_0,
  motores_supino_1: motores_supino_1,
  postura_cabeca_0: postura_cabeca_0,
  postura_cabeca_0_0: postura_cabeca_0_0,
  postura_cabeca_0_1: postura_cabeca_0_1,
  postura_cabeca_1: postura_cabeca_1,
  postura_cabeca_1_0: postura_cabeca_1_0,
  postura_cabeca_1_1: postura_cabeca_1_1,
  postura_cabeca_3: postura_cabeca_3,
  postura_supino_0: postura_supino_0,
  postura_supino_1: postura_supino_1,
  postura_supino_3: postura_supino_3,
  postura_tronco_0: postura_tronco_0,
  postura_tronco_0_1: postura_tronco_0_1,
  postura_tronco_0_2: postura_tronco_0_2,
  postura_tronco_0_3: postura_tronco_0_3,
  postura_tronco_1: postura_tronco_1,
  postura_tronco_3: postura_tronco_3,
  reacao_anterior_1: reacao_anterior_1,
  reacao_anterior_3: reacao_anterior_3,
  reacao_braco_0: reacao_braco_0,
  reacao_braco_1: reacao_braco_1,
  reacao_braco_3: reacao_braco_3,
  reacao_lateral_0: reacao_lateral_0,
  reacao_lateral_1: reacao_lateral_1,
  reacao_lateral_2: reacao_lateral_2,
  reacao_lateral_3: reacao_lateral_3,
  reacao_vertical_0: reacao_vertical_0,
  reacao_vertical_1: reacao_vertical_1,
  reacao_vertical_3: reacao_vertical_3,
  tonus_cachecol_0: tonus_cachecol_0,
  tonus_cachecol_1: tonus_cachecol_1,
  tonus_cachecol_3: tonus_cachecol_3,
  tonus_cachecol_0_direita: tonus_cachecol_0_direita,
  tonus_cachecol_0_izquerda: tonus_cachecol_0_izquerda,
  tonus_cachecol_3_direita: tonus_cachecol_3_direita,
  tonus_cachecol_3_izquerda: tonus_cachecol_3_izquerda,
  tonus_ombro_0: tonus_ombro_0,
  tonus_ombro_1: tonus_ombro_1,
  tonus_ombro_3: tonus_ombro_3,
  tonus_popliteo_0: tonus_popliteo_0,
  tonus_popliteo_1_0: tonus_popliteo_1_0,
  tonus_popliteo_1_1: tonus_popliteo_1_1,
  tonus_popliteo_2: tonus_popliteo_2,
  tonus_popliteo_3_0: tonus_popliteo_3_0,
  tonus_popliteo_3_1: tonus_popliteo_3_1,
  tonus_quadril_0: tonus_quadril_0,
  tonus_quadril_1: tonus_quadril_1,
  tonus_quadril_2: tonus_quadril_2,
  tonus_quadril_3: tonus_quadril_3,
  tonus_quadril_3_0: tonus_quadril_3_0,
  tonus_quadril_3_1: tonus_quadril_3_1,
  tonus_sentar_0: tonus_sentar_0,
  tonus_sentar_1: tonus_sentar_1,
  tonus_sentar_3: tonus_sentar_3,
  tonus_sentar_3_0: tonus_sentar_3_0,
  tonus_sentar_3_1: tonus_sentar_3_1,
  tonus_tornozelo_0: tonus_tornozelo_0,
  tonus_tornozelo_1_0: tonus_tornozelo_1_0,
  tonus_tornozelo_1_1: tonus_tornozelo_1_1,
  tonus_tornozelo_2: tonus_tornozelo_2,
  tonus_tornozelo_3_0: tonus_tornozelo_3_0,
  tonus_tornozelo_3_1: tonus_tornozelo_3_1,
  tonus_ventral_0: tonus_ventral_0,
  tonus_ventral_1: tonus_ventral_1,
  tonus_ventral_3_0: tonus_ventral_3_0,
  tonus_ventral_3_1: tonus_ventral_3_1,
};

function SectionOneSlide({
  title,
  topics,
  updateScore,
  isSection2,
  multipleSelectedScore,
}) {
  const nav = useNavigate();

  const checkForLineBreak = (t) => {
    if (!t.includes("<br>")) return t;

    return t.split("<br>").map((m) => <p>{m}</p>);
  };

  const processTitle = (title) => {
    if (!title.includes("<b>")) {
      return checkForLineBreak(title);
    }

    const titleSplit = title.split("<b>");
    const w = titleSplit.map((t) => {
      if (!t.includes("</b>")) return checkForLineBreak(t);
      const tSplit = t.split("</b>");
      return (
        <span>
          <b>{checkForLineBreak(tSplit[0])}</b>
          {checkForLineBreak(tSplit[1])}
        </span>
      );
    });
    return w;
  };

  const [marcado, setMarcado] = useState(false);

  return (
    <Card fullWidth>
      <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
        textAlign="center"
      >
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          {title}
        </MDTypography>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{ mt: 1, mb: 2 }}
        ></Grid>
      </MDBox>
      <MDBox fullWidth>
        <MDBox component="form" role="form">
          {topics.map((topic) => {
            console.log(topic)
            let ifDois = (Object.keys(topic).includes("selectedScoreDireita") && Object.keys(topic).includes("selectedScoreIzquerda")
            && topic.selectedScoreDireita !== -1
            && topic.selectedScoreIzquerda !== -1) 
            let isMarcado =
            // Tronco
            topic.selectedScore !== "tronco_0_0" 
            &&
            //Seção 2
            (Object.keys(topic).includes("selectedScore") || ifDois)
            && 
            //Geral
            (
              ifDois || 
              (!Object.keys(topic).includes("hasRl")
              && topic.selectedScore !== -1)
            );


            return (
              <Accordion>
                <AccordionSummary
                style={{ border: `2px solid ${isMarcado ? "green" : "red"}`, borderRadius: 10 }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="subtitle1">
                    <b>{topic.title}</b>
                  </Typography>
                </AccordionSummary>


                <AccordionDetails>
                  {topic.description && (
                    <Typography variant="subtitle2">
                      {topic.description}
                    </Typography>
                  )}

                {/* marcar aqui */}


                  <table style={{ width: "100%" }}>
                    <tr>
                      <th bgColor={"gray"}>Escore</th>
                      <th width="20%">Descrição</th>
                      {Object.keys(topic).includes("hasRl") && <th>E</th>}
                      {Object.keys(topic).includes("hasRl") && <th>D</th>}
                      {!Object.keys(topic).includes("hasRl") && <th>Esc.</th>}
                    </tr>
                    {Object.keys(topic.scores)
                      .sort((i, j) => (i < j ? 1 : -1))
                      .map((score) => {
                        const hasRightLeft = Object.keys(
                          topic.scores[score]
                        ).includes("rl");
                        const splitEachRl = Object.keys(
                          topic.scores[score]
                        ).includes("splitEachRl");
                        const hastMultiple = Object.keys(
                          topic.scores[score]
                        ).includes("multiple");
                        let titleColSpan = hastMultiple ? 2 : 1;
                        if (hastMultiple && splitEachRl) {
                          titleColSpan = 3;
                        }
                        let multipleWithSimpleTitle = Object.keys(
                          topic.scores[score]
                        ).includes("multipleSimpleTitle");

                        return (
                          <tr style={{ height: score === 2 ? "20px" : "" }}>
                            <td
                              bgColor={"gray"}
                              style={{ textAlign: "center", height: "20px" }}
                            >
                              <b>{topic.scores[score]["score"]}</b>
                            </td>
                            <td
                              width="50%"
                              colspan={titleColSpan}
                              style={{ padding: "4px" }}
                            >
                              {multipleWithSimpleTitle && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <p>
                                    {processTitle(topic.scores[score].title)}
                                  </p>
                                  <div style={{ display: "flex" }}>
                                    {topic.scores[
                                      score
                                    ].multipleSimpleTitle.map((o) => (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <img src={imgs[o.img]} alt="" />
                                        <input
                                          type="radio"
                                          id={`mult_{score}_{mi}`}
                                          checked={
                                            topic?.multipleSelectedScore ===
                                              o.id &&
                                            ((topic.selectedScore?.includes &&
                                              topic?.selectedScore?.includes(
                                                `${topic.key}_${score}`
                                              )) ||
                                              topic?.selectedScoreDireita?.includes(
                                                `${topic.key}_${score}`
                                              ) ||
                                              topic?.selectedScoreIzquerda?.includes(
                                                `${topic.key}_${score}`
                                              ))
                                          }
                                          onChange={(evt) => {
                                            if (
                                              (topic?.selectedScore?.includes &&
                                                topic?.selectedScore?.includes(
                                                  `${topic.key}_${score}`
                                                )) ||
                                              topic?.selectedScoreDireita?.includes(
                                                `${topic.key}_${score}`
                                              ) ||
                                              topic?.selectedScoreIzquerda?.includes(
                                                `${topic.key}_${score}`
                                              )
                                            )
                                              updateScore(
                                                topic.key,
                                                o.id,
                                                null,
                                                "multipleSelectedScore",
                                                null,
                                                score
                                              );
                                          }}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                              {!hastMultiple && !multipleWithSimpleTitle && (
                                <div style={{ justifyContent: "center" }}>
                                  <div>
                                    {topic.scores[score].img && (
                                      <img
                                        src={imgs[topic.scores[score].img]}
                                        alt=""
                                        style={{
                                          maxWidth: "100%", // Limita a largura máxima para manter a razão de aspecto
                                          height: "auto", // Ajusta a altura automaticamente para manter a proporção
                                        }}
                                      />
                                    )}
                                    <p>
                                      {processTitle(topic.scores[score].title)}
                                    </p>
                                  </div>
                                </div>
                              )}
                              {hastMultiple && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    textAlign: "center",
                                  }}
                                >
                                  {topic.scores[score].multiple.map(
                                    (multipleEntry, mi) => {
                                      return (
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            marginRight: "10px",
                                          }}
                                        >
                                          <img
                                            alt=""
                                            style={{
                                              width: "40px",
                                              height: "40px",
                                            }}
                                            src={imgs[multipleEntry.img]}
                                          />
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              id={`mult_{score}_{mi}`}
                                              checked={
                                                topic.selectedScore ===
                                                multipleEntry.id
                                              }
                                              onChange={(evt) => {
                                                updateScore(
                                                  topic.key,
                                                  multipleEntry.id,
                                                  null,
                                                  "selectedScore",
                                                  null,
                                                  score
                                                );
                                              }}
                                            />
                                            <label for={`mult_{score}_{mi}`}>
                                              {processTitle(
                                                multipleEntry.title
                                              )}
                                            </label>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                            </td>
                            {!hastMultiple && (
                              <td style={{ textAlign: "center" }}>
                                {splitEachRl && (
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                        justifyContent: "center",
                                        gap: 2,
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          padding: "10px",
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: "40px",
                                            height: "40px",
                                          }}
                                          alt=""
                                          src={imgs[topic.scores[score].img_i]}
                                        />
                                        <input
                                          type="radio"
                                          id={`II_${score}`}
                                          onChange={(evt) => {
                                            updateScore(
                                              topic.key,
                                              `${topic.scores[score].idIzquerda}_0`,
                                              null,
                                              "selectedScoreIzquerda",
                                              null,
                                              score
                                            );
                                          }}
                                          checked={
                                            `${topic.scores[score].idIzquerda}_0` ===
                                            topic.selectedScoreIzquerda
                                          }
                                        />
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: "40px",
                                            height: "40px",
                                            marginLeft: "20px",
                                          }}
                                          alt=""
                                          src={imgs[topic.scores[score].img_d]}
                                        />
                                        <input
                                          type="radio"
                                          id={`ID_${score}`}
                                          onChange={(evt) => {
                                            updateScore(
                                              topic.key,
                                              `${topic.scores[score].idIzquerda}_1`,
                                              null,
                                              "selectedScoreIzquerda",
                                              null,
                                              score
                                            );
                                          }}
                                          checked={
                                            `${topic.scores[score].idIzquerda}_1` ===
                                            topic.selectedScoreIzquerda
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {!splitEachRl && (
                                  <div>
                                    {Object.keys(topic.scores[score]).includes(
                                      "img_i"
                                    ) && (
                                      <img
                                        src={imgs[topic.scores[score].img_i]}
                                        alt=""
                                      />
                                    )}
                                    <input
                                      checked={
                                        hasRightLeft
                                          ? topic.selectedScoreIzquerda ===
                                            topic.scores[score].idIzquerda
                                          : topic.selectedScore ===
                                            topic.scores[score].id
                                      }
                                      onChange={(evt) => {
                                        updateScore(
                                          topic.key,
                                          hasRightLeft
                                            ? topic.scores[score].idIzquerda
                                            : topic.scores[score].id,
                                          null,
                                          hasRightLeft
                                            ? "selectedScoreIzquerda"
                                            : "selectedScore",
                                          null,
                                          score
                                        );
                                      }}
                                      type="radio"
                                    />
                                  </div>
                                )}
                              </td>
                            )}
                            {!hastMultiple && hasRightLeft && (
                              <td style={{ textAlign: "center" }}>
                                {splitEachRl && (
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div>
                                        <img
                                          style={{
                                            width: "40px",
                                            height: "40px",
                                            marginLeft: "40px",
                                          }}
                                          alt=""
                                          src={imgs[topic.scores[score].img_i]}
                                        />
                                        <input
                                          type="radio"
                                          id={`II_${score}`}
                                          onChange={(evt) => {
                                            updateScore(
                                              topic.key,
                                              `${topic.scores[score].idDireita}_0`,
                                              null,
                                              "selectedScoreDireita",
                                              null,
                                              score
                                            );
                                          }}
                                          checked={
                                            `${topic.scores[score].idDireita}_0` ===
                                            topic.selectedScoreDireita
                                          }
                                        />
                                      </div>
                                      <div>
                                        <img
                                          style={{
                                            width: "40px",
                                            height: "40px",
                                            marginLeft: "40px",
                                          }}
                                          alt=""
                                          src={imgs[topic.scores[score].img_d]}
                                        />
                                        <input
                                          type="radio"
                                          id={`ID_${score}`}
                                          onChange={(evt) => {
                                            updateScore(
                                              topic.key,
                                              `${topic.scores[score].idDireita}_1`,
                                              null,
                                              "selectedScoreDireita",
                                              null,
                                              score
                                            );
                                          }}
                                          checked={
                                            `${topic.scores[score].idDireita}_1` ===
                                            topic.selectedScoreDireita
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {!splitEachRl && (
                                  <div>
                                    {Object.keys(topic.scores[score]).includes(
                                      "img_d"
                                    ) && (
                                      <img
                                        src={imgs[topic.scores[score].img_d]}
                                        alt=""
                                      />
                                    )}
                                    <input
                                      checked={
                                        topic.selectedScoreDireita ===
                                        topic.scores[score].idDireita
                                      }
                                      onChange={(evt) => {
                                        updateScore(
                                          topic.key,
                                          topic.scores[score].idDireita,
                                          null,
                                          "selectedScoreDireita",
                                          null,
                                          score
                                        );
                                      }}
                                      type="radio"
                                    />
                                  </div>
                                )}
                              </td>
                            )}
                          </tr>
                        );
                    })}
                  </table>

                {/* marcar aqui */}



                  {isSection2 && (
                    <Typography variant="subtitle2" sx={{ margin: "5px" }}>
                      Idade (meses) que a habilidade máxima é alcançada
                    </Typography>
                  )}

                  {isSection2 && (
                    <MDBox fullWidth>
                      <TextField
                        label="Idade relatada"
                        variant="outlined"
                        outlined={false}
                        fullWidth
                        sx={{ margin: "10px" }}
                      />
                    </MDBox>
                  )}

                  <MDBox fullWidth>
                    <TextField
                      label="Observação"
                      multiline
                      minRows={5}
                      variant="outlined"
                      outlined={false}
                      fullWidth
                      sx={{ margin: "10px" }}
                    />
                  </MDBox>

                  {!isSection2 && (
                    <MDBox fullWidth>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={topic.ass}
                            onChange={(evt) => {
                              updateScore(topic.key, null, evt.target.checked);
                            }}
                          />
                        }
                        label={`Assimetria`}
                      />
                    </MDBox>
                  )}
                  {!isSection2 && (
                    <Box>
                      <Typography variant="subtitle1">
                        Pontuação do tôpico: {topic?.points || 0}
                      </Typography>
                    </Box>
                  )}
                </AccordionDetails>

              </Accordion>
            );
          })}
        </MDBox>
        <MDBox>
          <MDBox sx={{ display: "flex", flexDirection: "row" }} mt={4}>
            <MDButton
              sx={{ margin: "10px" }}
              variant="gradient"
              color="info"
              onClick={() => {
                if (hasUnselectedTopics(topics)) {
                  alert("Existem topicos sem pontuacao");
                } else {
                  nav(-1);
                }
              }}
              fullWidth
            >
              Salvar
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default SectionOneSlide;
