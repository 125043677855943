import jsPDF from "jspdf";
import fapeamLogo from "../main-menu/fapeam.png";
import hineLogo from "../main-menu/hine.png";
import labtamLogo from "../main-menu/labtam.png";
import ufamlogo from "../main-menu/ufam.png";

import moment from "moment";

const setCabecalho = (doc, direcoes, gap, centerGap, ...images) => {
  let i = 0;
  let cursorLeft = 10;
  let cursorRight = 600 - 10;
  let cursorUpper = 10;

  let acc = 600;
  acc -= centerGap;
  acc -= 20;
  let j = 0;
  let accWidth = 0;
  let accHeight = 0;

  let quantGapR = 0, quantGapL = 0;

  images.forEach(image => {
    if(direcoes[j] === "R") {
      quantGapR++;
    } else {
      quantGapL++;
    }
    accWidth += image.w;
    accHeight += image.h;
    j++;
  })


  acc -= (quantGapR - 1) * gap;
  acc -= (quantGapL - 1) * gap;

  const multiplicador = acc / accWidth;
  const mediaHeight = accHeight / images.length;

  let quantR = 0, quantL = 0;

  let heightMaior = -1;

  images.forEach((images) => {
    const { src, w, h } = images;
    const newWidth = w * multiplicador;
    const newHeigth = newWidth * h / w;
    if(heightMaior < newHeigth) {
      heightMaior = newHeigth;
    }
    if(direcoes[i] == "l") {
      quantL++;
      doc.addImage(src, "PNG", cursorLeft, cursorUpper, newWidth, newHeigth);
      cursorLeft += newWidth + gap;
    } else {
      if(quantR !== 0) {
        cursorRight -= gap;
      }
      quantR++;
      cursorRight -= newWidth;
      doc.addImage(src, "PNG", cursorRight, cursorUpper, newWidth, newHeigth);
    }
    i++;
  })

  return heightMaior + 10;

}

const generateTable = (doc, inicio, data, width, centralize) => {
  let y = inicio;
  let x = 20;

  const columnWidths = width;
  const rowStyle = { fillColor: "#f2f2f2" };

  data.forEach((row) => {
    row.forEach((cell, index) => {
      let xCell = x + 2;
      if(centralize) {
        xCell += (columnWidths[index] / 2) - cell.length * 5 / 2;  
      }

      doc.setFillColor(rowStyle.fillColor);
      doc.setTextColor(0, 0, 0);
      doc.rect(x, y, columnWidths[index], 15, "F");
      doc.line(x, y, x + columnWidths[index], y);
      doc.text(cell, xCell, y + 10);
      x += columnWidths[index];
    });
    y += 12;
    x = 20;
  });

  return y;
}

const calcAssimetria = (doc, inicio, data) => {
  console.log(data.hine[0].subsections)

  let assimetrias = [];

  data.hine[0].subsections.forEach((section) => {
    section.topics.forEach((topic) => {
      if(topic.ass) {
        let title = "";
        switch(section.title) {
          case "AVALIAÇÃO DA FUNÇÃO DOS NERVOS CRANIANOS":
            title = "Função de Nervos Cranianos";

            break;
          case "AVALIAÇÃO DOS MOVIMENTOS":
            title = "Movimentos";

            break;
          case "AVALIAÇÃO DA POSTURA":
            title = "Postura";

            break;
          case "AVALIAÇÃO TÔNUS":
            title = "Tônus";

            break;
          case "REFLEXOS E REAÇÕES":
            title = "Reflexos e Reações";

            break;
        }


        assimetrias.push({ topic: topic.title, section: title });
      }
    })
  })

  let cursor = inicio;

  doc.text(`Número de assimetrias: ${assimetrias.length}`, 20, cursor);

  if(assimetrias.length > 0) {
    cursor += 30;
    doc.text("Assimetrias encontradas em:", 20, cursor);
    cursor += 20;
    assimetrias.forEach((assimetria) => {
      doc.text(`${assimetria.section}: ${assimetria.topic}`, 20, cursor);
      cursor += 15;
    })

  }

  return cursor;
}

const generateAnalisis = (doc, inicio, pontuacao, situacao, gap) => {
  let cursor = inicio;
  
  doc.text("Análise:", 20, cursor);

  cursor += gap;

  doc.text(`(${situacao ? "x" : "  "}) Pontuação ótima`, 20, cursor);
  cursor += 10;
  doc.text(`(${situacao ? "  " : "x"}) Pontuação sub-ótima`, 20, cursor);

  cursor += gap;

  const messageOtimo = "ausência de risco para alteração neuromotora/paralisia cerebral.";
  const messageSubOtimo = "predição de risco para alteração neuromotora/paralisia cerebral.";

  const line = doc.splitTextToSize(`O(A) paciente apresenta pontuação global de ${pontuacao}, correspondendo a escore ${pontuacao}, o que caracteriza ${situacao ? messageOtimo : messageSubOtimo}`, 555);

  doc.text(line, 20, cursor);
}

export default function createPdf(
  name,
  patientData,
  hineData,
  recordDate,
  doctorData
) {
  const doc = new jsPDF("p", "pt", "a4", false);
  doc.setFontSize(10);

  const hineImage = {
    src: hineLogo,
    w: 850,
    h: 124
  }

  const ufamImage = {
    src: ufamlogo,
    w: 121, 
    h: 126
  }

  const fapeamImage = {
    src: fapeamLogo,
    w: 299,
    h: 86

  }

  const labtamImage = {
    src: labtamLogo,
    w: 290,
    h: 133
  }
  
  const cabecalhoEnd = setCabecalho(doc, ["l", "r", "r", "r"], 5, 50, hineImage, ufamImage, fapeamImage, labtamImage) + 10;

  const data = [
    ["Nome", patientData.name, "CNS", "xxx xxxx xxxx xxxx"],
    ["Data de Nascimento", moment(patientData.birthDate).format("YYYY-MM-DD"), "Sexo", patientData.sex],
    ["Semanas de gestação", patientData?.idadeGestacional || "Desconhecido", "", ""],
    ["Idade corrigida", patientData.idadeCorrigida, "Idade cronológica", patientData.idadeCronologica],
    ["Data de Avaliação", moment(recordDate).format("YYYY-MM-DD HH:MM"), "", ""],
    ["Avaliador(a)", doctorData.name, "Email do avalidor(a):", doctorData.email]
  ];

  const columnWidths = [120, 160, 120, 155];

  const tableEnd = generateTable(doc, cabecalhoEnd, data, columnWidths, 0) + 20;

  const totalPoints = hineData.hine[0].subsections.reduce((a, b) => {

    if(typeof a === 'number') {
      return a + b.points
    }

    return a.points + b.points;
  });

  const endHeaderDiagnosis = generateTable(doc, tableEnd, [[`Seção I - ${totalPoints}pts`]], [555], 1);

  const pointsSub = hineData.hine[0].subsections.map(sub => sub?.points.toString());

  const endTableSect = generateTable(doc, endHeaderDiagnosis, [["Função de Nervos Cranianos", "Postura", "Movimentos", "Tônus", "Reflexos e Reações"], pointsSub], [135,101,102,106,111], 1) + 50;

  const monthsDiff = moment().diff(patientData.birthDate, "months");
  const situacao =
    (monthsDiff <= 3 && totalPoints >= 56) ||
    (monthsDiff <= 6 && totalPoints >= 59) ||
    (monthsDiff <= 9 && totalPoints >= 62) ||
    (monthsDiff <= 12 && totalPoints >= 65);

  const endAss = calcAssimetria(doc, endTableSect, hineData) + 30;

  generateAnalisis(doc, endAss, totalPoints, situacao, 25);


  doc.save(`${name}.pdf`);
}
